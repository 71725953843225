<template>
  <TopicsCard />
</template>

<script>
import TopicsCard from '@/components/TopicsCard';

export default {
  components: {
    TopicsCard
  }
};
</script>
